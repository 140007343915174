import * as React from 'react';
import styled, { css } from 'styled-components';
import { TextField as TextFieldBase } from 'Client/components/molecules';
import { ChevronIcon } from 'Icons';

export const Wrapper = styled.div<{ size?: string }>`
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 2rem 0.6875rem 0.6875rem;
  justify-content: flex-start;

  a {
    color: ${({ theme }) => theme.colors.green[500]};
  }

  img {
    width: inherit;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 0.5rem 0.6875rem;
    }
  `};
`;

export const Title = styled.div<{ size?: string }>`
  font-weight: 700;
  font-size: ${({ size }) => (size === 'small' ? '1.75rem' : '2.625rem')};
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  ${({ theme, size }) => css`
    ${theme.breakpoints.down('sm')} {
      justify-content: center;
      width: 80%;
      text-align: center;
      font-size: ${size === 'small' ? '1.75rem' : '2rem'};
    }
  `};
`;

export const Form = styled.div<{ page?: string }>`
  display: flex;
  flex-direction: column;
  ${({ page }) =>
    page === 'reset-password' &&
    css`
      padding-bottom: 1.125rem;
    `}

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 1rem;
    }
  `};
`;

export const Image = styled.div`
  width: 8.4375rem;
  height: 8.4375rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div<{ size?: string; page?: string }>`
  display: flex;
  margin-top: ${({ page }) => (page === 'main-login' ? '6.5rem' : '1.25rem')};
  margin-bottom: ${({ size }) => (size === 'small' ? 0 : '3rem')};

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      align-items: center;
      flex-direction: column;
      text-align: center;
    }

    ${theme.breakpoints.down('md')} {
      margin-top: 1.25rem;
      margin-bottom 1.25rem;
    }
  `};
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 1rem 0;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      text-align: center;
    }
  `}
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.4375rem;
`;

export const ErrorSpacer = styled.div<{ size?: string; error?: boolean }>`
  height: ${({ size }) => (size === 'small' ? '14vh' : '30%')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${({ theme, size, error }) => css`
    ${theme.breakpoints.down('sm')} {
      height: ${size === 'small' ? (error ? '8vh' : 0) : '8vh'};
    }
  `};

  ${({ theme, size }) => css`
    ${theme.breakpoints.up('xl')} {
      height: ${size === 'small' ? '6vh' : ' 12vh'};
    }
  `};
`;

export const TextField = styled(({ ...props }) => <TextFieldBase {...props} />)`
  ${({ theme }) => css`
    width: 100%;
    min-width: 0 !important;
    margin: 0.625rem 0 0.5rem;

    :focus {
      box-shadow: 0rem 0rem 0rem 0.25rem rgba(121, 133, 168, 0.2);
      border-radius: 0.3125rem;
    }
    :focus-within {
      box-shadow: 0rem 0rem 0rem 0.25rem rgba(121, 133, 168, 0.2);
      border-radius: 0.3125rem;
    }

    .MuiOutlinedInput-root {
      border: 0.0625rem solid ${theme.colorMappings.loginInputBorderColor};
      height: 2.5625rem;
    }

    .MuiOutlinedInput-root fieldset {
      border-color: ${theme.colorMappings.loginInputBorderColor};
      top: 0;
    }

    .Mui-focused {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border: red;
          border-radius: 0.3125rem;
        }
      }
    }

    .MuiInputBase-input {
      &:focus {
        padding: 0.625rem 0.9rem;
      }
    }
    .MuiOutlinedInput-root {
      &.Mui-error {
        &.MuiOutlinedInput-notchedOutline {
          border: 0.0625rem solid rgb(121, 133, 168);
          box-shadow: 0rem 0rem 0rem 0.25rem ${theme.colors.red[800]};
          border-radius: 0.3125rem;
        }
      }
    }

    .MuiOutlinedInput-root {
      &.Mui-disabled {
        background: ${theme.colorMappings.inputBorder};
        opacity: 0.5;
      }
    }
    span {
      position: absolute;
      top: 2.375rem;
      width: 100%;
    }

    .MuiIconButton-label {
      position: initial;
    }
  `}
  ${({ status }) =>
    status?.type === 'error' &&
    status?.show &&
    css`
      margin-bottom: 4.0625rem;
    `}
`;

export const Tip = styled.p<{ left?: boolean }>`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0.5rem 0 1rem;
  display: flex;
  flex-direction: ${({ left }) => (left ? 'column' : 'row')};
  align-items: ${({ left }) => (left ? 'baseline' : 'center')};
  justify-content: center;

  b {
    display: contents;
  }

  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ForgotPassword = styled.a`
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.green[500]};
  width: 100%;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1.5rem;
  cursor: pointer;

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: 0 1rem;
    }
  `};
`;

export const SignInButton = styled.div<{ small?: boolean }>`
  height: 2.9375rem;
  width: ${({ small }) => (small ? '50%' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.green[500]};
  border-radius: 0.125rem;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.colors.white[500]};
  cursor: pointer;
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      font-size: 1rem;
    }
  `};

  :hover {
    background: ${({ theme }) => theme.colors.green[600]};
  }
`;

export const SSOButton = styled.div`
  height: 2.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white[500]};
  border-radius: 0.125rem;
  border: 0.0625rem solid ${({ theme }) => theme.colorMappings.microsoftSSOGrey};
  font-size: 1rem;
  color: ${({ theme }) => theme.colorMappings.microsoftSSOGrey};
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 0.5rem;
  padding-right: 0.75rem;

  svg {
    width: 1.5625rem;
    height: 1.5625rem;
    margin: 0 0.75rem;
  }
`;

export const Banner = styled.div`
  background: ${({ theme }) => theme.colors.grey[50]};
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[200]};
  border-radius: 0.1875rem;
  display: flex;
  align-items: center;
  height: 6rem;
  margin-top: 2.5rem;
`;

export const BannerImage = styled.div`
  background: ${({ theme }) => theme.colorMappings.loginBannerImageBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.5625rem;
  height: 100%;
  padding: 0 0.625rem;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 115%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.75rem 1.375rem 1.75rem 1.375rem;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const BackButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.green[500]};
  font-size: 1rem;
  font-weight: 700;
  line-height: 0.0625rem;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const Arrow = styled(ChevronIcon)`
  transform: scale(1);
  margin-right: 0.4rem;
`;

export const ConfirmationDiv = styled.div`
  background: ${({ theme }) => theme.colorMappings.alertSuccessBg};
  border-left: 0.125rem solid
    ${({ theme }) => theme.colorMappings.CommonplaceBrand};
  color: ${({ theme }) => theme.colorMappings.greenText};
  height: 6.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-bottom: 4rem;
`;

export const AlertTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.125rem;
`;
